import { UserType, auth, firebaseAuthProviders, useLoginResultType } from '@/configs/firebase';
import { useBluwhale } from '@/context/BluwhaleContext';
import { onBluwhaleLoginOptionType, onLoginOptionType } from '@/types/login';
import { TwitterAuthProvider, fetchSignInMethodsForEmail, linkWithCredential, signInWithPopup } from 'firebase/auth';
import useLoginByBluwhaleAuth from './useLoginByBluwhaleAuth';
import { basicConfig } from '@/configs';
import * as privy from '@/apis/privy';
import { PrivyAuthSuccessResponse } from '@/apis/privy/types';
import { User, useLogin, useLogout, usePrivy } from '@privy-io/react-auth';
import { useEffect, useRef } from 'react';

export default function useLoginByPrivy() {
  // Need to set user type when call signin or link
  const userTypeRef = useRef<UserType>('consumer');
  const actionTypeRef = useRef<'signin' | 'link'>('signin');
  const isSignUpRef = useRef<boolean | undefined>();
  const accessTokenRef = useRef<string | undefined>();
  const BASIC_URL = basicConfig.auth.servicesBasicUrl;
  const { onSetUser, onUpdateUser, setLoading, onSetPrivyData, firebaseUser } = useBluwhale();
  const { onSignin, onLink, onPrivySignin } = useLoginByBluwhaleAuth({ BASIC_URL });
  const { logout, getAccessToken, user: privyUser } = usePrivy();
  const { login } = useLogin({
    onComplete: (user: User, isNewUser: boolean, wasAlreadyAuthenticated: boolean, loginMethod: any | null) =>
      handlePrivyLoginCompleted(user, isNewUser, wasAlreadyAuthenticated),
    onError: handlePrivyLoginFailure,
  });

  async function handlePrivyLoginCompleted(user: any, isNewUser: any, wasAlreadyAuthenticated: any) {
    console.log('handlePrivyLoginCompleted', user, isNewUser, wasAlreadyAuthenticated);
    // if (!props) {
    //   setLoading?.(false);

    //   return;
    // }
    // 1. get firebase custom token
    // const token = await getAccessToken();
    // if (!token) {
    //   throw new Error('token is null');
    // }
    // const result = await onPrivySignin({ token });
    // if (!result?.token || !result?.credential) {
    //   throw new Error('customToken is null');
    // }
    // 2. sign in with custom token and user type to bluwhale auth api
    // if (actionTypeRef.current === 'signin') {
    //   await bluwhaleSignin({
    //     userType: userTypeRef.current,
    //     isSignUp: isSignUpRef.current,
    //     credential: result?.credential,
    //     token: result.token,
    //   });
    //   return;
    // }
    // await bluwhaleLink({
    //   accessToken: accessTokenRef.current,
    //   userType: userTypeRef.current,
    //   isSignUp: isSignUpRef.current,
    //   credential: result?.credential,
    //   token: result.token,
    // });
  }
  /**
   *
   * @param error
   * @returns
   * @description
   * because of the firebase auth only allow same gmail to only login one account,
   * so we need to link the account if the user want to login with the same gmail
   */
  async function handlePrivyLoginFailure(error: any) {
    console.debug('handlePrivyLoginFailure', error);
    console.log('error', error);
    setLoading?.(false);
  }

  async function bluwhaleSignin({ userType, isSignUp, credential, token }: onBluwhaleLoginOptionType) {
    try {
      if (!token) {
        throw new Error('token is null');
      }
      setLoading?.(true);
      //TODO
      const bluwhaleUser = await onSignin({ token, userType, isSignUp });
      onSetUser({ credential, user: bluwhaleUser });
      setLoading?.(false);
      return { result: bluwhaleUser, action: 'signin', isSuccess: true };
    } catch (e) {
      setLoading?.(false);

      console.log('e', e);
      return { result: undefined, action: 'signin', isSuccess: false, error: e };
    }
  }
  async function bluwhaleLink({ accessToken, userType, token, credential }: onBluwhaleLoginOptionType) {
    const preToken = accessToken ?? (await auth.currentUser?.getIdToken());
    const preUser = auth.currentUser;
    if (!preToken || !preUser) {
      setLoading?.(false);
      throw new Error('No preToken received');
    }

    const bluwhaleUser = await onLink({ token, preToken, userType });
    setLoading?.(false);

    if (!bluwhaleUser) {
      throw new Error('No bluwhaleUser received');
    }
    onUpdateUser({ firebaseUser: preUser, token: preToken, user: bluwhaleUser });

    return { result: bluwhaleUser, action: 'link', isSuccess: true };
  }
  async function handlePrivySignin() {
    const token = await getAccessToken();
    if (!token) {
      throw new Error('token is null');
    }
    const result = await onPrivySignin({ token });
    if (!result?.token || !result?.credential) {
      throw new Error('customToken is null');
    }
    return result;
  }

  async function signin({ userType, isSignUp }: onLoginOptionType): Promise<useLoginResultType> {
    try {
      setLoading?.(true);
      const { token, credential } = await handlePrivySignin();

      const bluwhaleUser = await onSignin({ token, userType, isSignUp });
      onSetUser({ credential, user: bluwhaleUser });
      setLoading?.(false);
      return { result: bluwhaleUser, action: 'signin', isSuccess: true };
    } catch (e) {
      setLoading?.(false);
      console.log('e', e);
      return { result: undefined, action: 'signin', isSuccess: false, error: e };
    }
  }
  async function link({ accessToken, userType, isSignUp }: onLoginOptionType): Promise<useLoginResultType> {
    try {
      const preToken = accessToken ?? (await auth.currentUser?.getIdToken());
      const preUser = auth.currentUser;
      if (!preToken || !preUser) {
        setLoading?.(false);
        throw new Error('No preToken received');
      }
      setLoading?.(true);
      const { token, credential } = await handlePrivySignin();

      const bluwhaleUser = await onLink({ token, preToken, userType });

      if (!bluwhaleUser) {
        throw new Error('No bluwhaleUser received');
      }
      onUpdateUser({ firebaseUser: preUser, token: preToken, user: bluwhaleUser });

      return { result: bluwhaleUser, action: 'link', isSuccess: false };
    } catch (e) {
      setLoading?.(false);

      console.log('e', e);
      return { result: undefined, action: 'link', isSuccess: false, error: e };
    }
  }
  async function sendMail(email: string): Promise<boolean> {
    const res = await privy.sendMail(email);
    return res;
  }
  async function authenticate(email: string, code: string): Promise<PrivyAuthSuccessResponse | undefined> {
    const res = await privy.authenticate(email, code);
    if (!res) {
      return undefined;
    }
    onSetPrivyData(res);
    return res;
  }
  return { onSignin: signin, onLink: link };
}
