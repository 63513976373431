import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import React from 'react';
import Link from 'next/link';
import { basicConfig } from '@/configs';
import { useRouter } from 'next/router';
import { useConnect } from 'wagmi';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import Layout from './Layout';
import Annotations from './Annotations';
import { useBluwhale } from '@/context/BluwhaleContext';
import Web3LoginButton from '../LoginButtons/Web3LoginButton';
import { ActionType, UserType } from '@/configs/firebase';
import { event } from 'nextjs-google-analytics';
import { events } from '@/constants/analytics';
import { isMobile } from 'react-device-detect';
import CommonLoginButton from '../LoginButtons/CommonLoginButton';
import PrivyLoginButton from '../LoginButtons/PrivyLoginButton';

export type Web3LoginPlatformProps = {
  action?: ActionType;
  title?: string;
  userType: UserType;
  showSwitchLable?: boolean;
  referralCode?: string;
  loading?: boolean;
};

function Web3LoginPlatform({
  action = 'signin',
  title = 'Login with Web3 Wallet',
  userType = 'consumer',
  showSwitchLable = false,
  referralCode,
  loading = false,
}: Web3LoginPlatformProps) {
  const router = useRouter();
  const params = new URLSearchParams(window.location.search);
  params.delete('type');
  const { showStep, from, claim, contract, projectname } = router.query;

  const { axiosInstance } = useBluwhale();
  const loginButtons = [
    <Web3LoginButton
      key="MetaMask"
      name="MetaMask"
      logo="/images/wallet/ic_metamask.png"
      action={action}
      web3Provider="metamask"
      userType={userType}
      isSignUp={true}
      referralCode={referralCode}
    />,
    <Web3LoginButton
      key="walletconnect"
      name="Walletconnect"
      logo="/images/wallet/ic_wallet_connect.png"
      action={action}
      web3Provider="walletconnect"
      userType={userType}
      isSignUp={true}
      referralCode={referralCode}
    />,
    <Web3LoginButton
      key="coinbase"
      name="Coinbase"
      logo="/images/wallet/ic_coinbase.png"
      web3Provider="coinbase"
      action={action}
      userType={userType}
      isSignUp={true}
      referralCode={referralCode}
    />,
  ];

  // const mobileButtons = [
  //   <Web3LoginButton
  //     key="walletconnect"
  //     name="Walletconnect V2"
  //     logo="/images/wallet/ic_wallet_connect.png"
  //     action={action}
  //     web3Provider="walletconnect"
  //     userType={userType}
  //     isSignUp={true}
  //     referralCode={referralCode}
  //   />,
  // ];

  // const loginButtons = isMobile ? mobileButtons : pcButtons;
  const {
    connect: connectMetaMask,
    error,
    isLoading,
  } = useConnect({
    connector: new MetaMaskConnector(),
  });

  const test = () => {
    axiosInstance
      ?.get('auth')
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const installWallet = () => {
    event(events.not_have_wallet);
  };
  return (
    <Layout title={title}>
      <Flex justifyContent={'center'} alignItems={'center'} flexDir="column" flex="1">
        {loading ? (
          <Flex minH={['60vh', 400, 400]} w="90vw" justifyContent={'center'} flexDir="column" alignSelf={'center'}>
            <Box my={4}>
              <Spinner thickness="4px" speed="0.65s" color="#6bb2c5" size="xl" />
            </Box>
            <Text color="#D6D6D6">Securely logging you in...</Text>
          </Flex>
        ) : (
          <Flex flexDir="column" mt={5} textAlign="center">
            <Box key={'Privy'}>
              <PrivyLoginButton
                name="Privy"
                logo=""
                action={action}
                userType={userType}
                isSignUp={true}
                referralCode={referralCode}
              />
            </Box>
            <Box marginY={'2'}>Or</Box>
            {loginButtons.map((item) => (
              <Box key={item.key}>{item}</Box>
            ))}
            {/* <Box mb={4}>
              <Link href={basicConfig.metaMaskUrl} className="underline" onClick={installWallet}>
                I don't have a wallet
              </Link>
            </Box> */}
            {showSwitchLable && (
              <Box mb={4}>
                <Link
                  href={`${params && params.size > 0 ? `?${params}` : ''}`}
                  className="underline text-sm text-gray-400"
                >
                  {action === 'link' ? 'link to other social media' : 'using social media to login'}
                </Link>
              </Box>
            )}
            <Annotations />
          </Flex>
        )}
      </Flex>
    </Layout>
  );
}
export default Web3LoginPlatform;
