import React, { ReactNode } from 'react';
import { Box, Image } from '@chakra-ui/react';
import { Default } from '../Default';

interface LoginLayoutProps {
  children: ReactNode;
}

function LoginLayout({ children }: LoginLayoutProps) {
  return (
    <Default pageName="Login" couldConnect={false}>
      <div className="flex flex-row w-full relative overflow-x-hidden ">
        <Image
          alt="banner"
          className="absolute  z-0"
          width={'100%'}
          // width={['100%', null, 1440]}
          height={272}
          left={[0, null, '20%']}
          top={[0, null, '100px']}
          src="/shape-banner-center.png"
          overflow={'hidden'}
        />

        <div className="width-[60%] m-[auto] ">{children}</div>
      </div>
    </Default>
  );
}

export default LoginLayout;
