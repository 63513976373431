import React, { useState } from 'react';
import CommonLoginButton from './CommonLoginButton';
import { LoginButtonProps } from '@/types/login';
import { useLoginResultType } from '@/configs/firebase';
import { useRouter } from 'next/router';
import { useToast } from '@chakra-ui/react';
import useLoginByWeb3 from '@/hooks/useLoginByWeb3';
import { useBluwhale } from '@/context/BluwhaleContext';
import { event } from 'nextjs-google-analytics';
import { events } from '@/constants/analytics';
import { isMobile } from 'react-device-detect';
function Web3LoginButton({
  name,
  logo,
  action = 'signin',
  web3Provider = 'metamask',
  userType,
  referralCode,
}: LoginButtonProps) {
  const { onSignin, onLink } = useLoginByWeb3({ web3Provider });
  const { firebaseUser, setLoading } = useBluwhale();
  const router = useRouter();
  const toast = useToast();
  function handleResult(result: useLoginResultType) {
    if (!result.isSuccess) {
      toast.closeAll();
      toast({
        description: `${action} failure`,
        position: 'top',
        status: 'error',
      });
      return;
    }
    event(events.connectwallet_success, {
      wallet: name,
    });
    const params = new URLSearchParams(window.location.search);
    if (params.get('status') !== undefined) {
      params.delete('status');
    }
    const path = `${params && params.size > 0 ? `?${params.toString()}&status=success` : '?status=success'}`;
    router.push(path);
  }
  async function onClick() {
    event(events.connectwallet_choose, {
      wallet: name,
    });
    if (isMobile && web3Provider === 'metamask' && !window?.ethereum) {
      document.location.href = `https://metamask.app.link/dapp/${window.location.href}`;
      return;
    }
    const accessToken = firebaseUser?.token;
    const props = { provider: web3Provider, userType, accessToken, referralCode };

    const result = action === 'link' ? await onLink(props) : await onSignin(props);
    setLoading?.(false);
    handleResult(result);
  }
  return <CommonLoginButton name={name} logo={logo} onClick={onClick} />;
}

export default Web3LoginButton;
