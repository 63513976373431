import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from './store';
import { incrementByAmount, toggleShowBlu, displayShow, increaseBlu, addProjectBlu } from './claimProfit';

export const useClaimProfit = (blu?: number) => {
  const dispatch = useDispatch<AppDispatch>();
  const showProfit = useSelector((state: RootState) => state.counter.showProfit);
  const addedBlu = useSelector((state: RootState) => state.counter.addedBlu);
  const totalBlu = useSelector((state: RootState) => state.counter.totalBlu);
  const projectBlu = useSelector((state: RootState) => state.counter.projectBlu);
  const addBlu = useCallback(
    (blu: number) => {
      dispatch(incrementByAmount(blu));
      dispatch(addProjectBlu(blu));
      dispatch(increaseBlu(blu));
    },
    [dispatch, incrementByAmount, blu],
  );
  const increaseProjectBlu = useCallback(
    (blu: number) => {
      dispatch(addProjectBlu(blu));
    },
    [dispatch, incrementByAmount, blu],
  );
  const toggleShowBluPop = useCallback(() => {
    dispatch(toggleShowBlu());
  }, [dispatch, toggleShowBlu]);

  const displayBluPop = useCallback(() => {
    dispatch(displayShow());
  }, [dispatch, toggleShowBlu]);

  return { showProfit, addBlu, toggleShowBluPop, addedBlu, displayBluPop, totalBlu, projectBlu, increaseProjectBlu };
};
