import { Box, Button, Image, Text } from '@chakra-ui/react';
import React from 'react';

function CommonLoginButton({
  name,
  logo,
  onClick,
  children,
}: {
  name: string;
  logo?: string;
  onClick: () => void;
  children?: React.ReactNode;
}) {
  return (
    <Box
      key={name}
      className="my-1 "
      bg="transparent"
      border="1px solid #FFFFFF33"
      display="flex"
      flexDir={'row'}
      alignItems={'center'}
      gap={2}
      p={[3, null, 3]}
      // pr={[0, null, 48]}
      rounded={'md'}
      width={'100%'}
      overflow={'hidden'}
      cursor={'pointer'}
      onClick={onClick}
    >
      {logo && <Image alt="checked" src={logo} width={38} height={38} />}
      {children ? (
        <>{children}</>
      ) : (
        <Box maxW={260}>
          <Text fontSize="16px" color="#D0D0D0" fontWeight="thin">
            Continue with {name}
          </Text>
        </Box>
      )}
    </Box>
  );
}

export default CommonLoginButton;
