/* eslint-disable no-confusing-arrow */
/* eslint-disable complexity */
import React, { useState } from 'react';
import { Box, useToast, Text, useBoolean, Spinner, Flex } from '@chakra-ui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { UserType, socialLoginProviders } from '@/configs/firebase';
import { claimingProject } from '../../../../pages/api/enterpriseapi';
import { useAccount } from 'wagmi';
import { useClaimProfit } from '../../../../redux/useClaimProfit';
import { GoogleLoginButton, TwitterLoginButton } from '@/components/modules/LoginButtons';
import Annotations from './Annotations';
import Layout from './Layout';
import TelegramLoginButton from '../LoginButtons/TelegramLoginButton';
import LinkedinLoginButton from '../LoginButtons/LinkedinLoginButton';
import { event } from 'nextjs-google-analytics';
import { events } from '@/constants/analytics';

export type SocialLoginPlatformProps = {
  action?: 'signin' | 'link';
  title?: string;
  userType: UserType;
  showSwitchLable?: boolean;
  claimProject?: boolean;
  loading?: boolean;
};
export default function SocialLoginPlatform({
  action = 'signin',
  title = 'Login with Social Media',
  userType = 'consumer',
  showSwitchLable = false,
  claimProject = false,
  loading = false,
}: SocialLoginPlatformProps) {
  const router = useRouter();
  const params = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : undefined;
  params?.delete('type');

  const { from, claim, projectname, contract, wallet_amount = 0 } = router.query;
  const { address: myAddress } = useAccount();
  const toast = useToast();
  const { showProfit: isOpen, addBlu, toggleShowBluPop, increaseProjectBlu } = useClaimProfit();
  const [showSignUp, setShowSignUp] = useState(claimProject);
  //#region  buttons
  const loginButtons = [
    <GoogleLoginButton
      key="google"
      name={socialLoginProviders['google'].name}
      logo={socialLoginProviders['google'].logo}
      action={action}
      userType={userType}
      isSignUp={showSignUp}
    />,
    <TwitterLoginButton
      key="twitter"
      name={socialLoginProviders['twitter'].name}
      logo={socialLoginProviders['twitter'].logo}
      action={action}
      userType={userType}
      isSignUp={showSignUp}
    />,
    <TelegramLoginButton
      key="telegram"
      name={socialLoginProviders['telegram'].name}
      logo={socialLoginProviders['telegram'].logo}
      action={action}
      userType={userType}
      isSignUp={showSignUp}
    />,
    <LinkedinLoginButton
      key="linkedin"
      name={socialLoginProviders['linkedin'].name}
      logo={socialLoginProviders['linkedin'].logo}
      action={action}
      userType={userType}
      isSignUp={showSignUp}
    />,
    // <FacebookLoginButton
    //   key="facebook"
    //   name={socialLoginProviders['facebook'].name}
    //   logo={socialLoginProviders['facebook'].logo}
    //   action={action}
    // />,
  ];
  //#endregion
  const consumerLoginBottons = loginButtons.filter((element) => element.key !== 'linkedin');
  const enterpriseLoginBottons = loginButtons.filter((element) => element.key !== 'telegram');
  const usedLoginBottons = userType === 'enterprise' ? enterpriseLoginBottons : consumerLoginBottons;
  async function handleClaimProject(_projectName: string, _contract: string) {
    const result = await claimingProject(_contract);
    toast({
      description: `Successfully claimed ${_projectName} `,
      status: 'success',
      position: 'top',
    });
    await addBlu(Number(wallet_amount) * 0.1);
    await increaseProjectBlu(Number(wallet_amount) * 0.1);
    toggleShowBluPop();
    // const enterpriseDetails = await checkEnterPriseDetail(_myAddress);
  }
  async function handleAfterUserLogin() {
    // if (!myAddress) {
    //   return;
    // }

    if (claim && projectname && contract) {
      const _projectName = projectname as string;
      const _contract = contract as string;
      //@ts-ignore
      await handleClaimProject(_projectName, _contract);
      router.push(`/enterprise`);
      return;
    }
    if (from) {
      router.push(from as string);
      return;
    }
    //TODO
    router.push(`/enterprise`);
  }
  const signUPClick = () => {
    event(events.signUp_click);
    setShowSignUp(true);
  };
  const EnterpriseText = () =>
    showSignUp ? (
      <Text onClick={() => setShowSignUp(false)}>
        Already have an account?{' '}
        <Text color={'#00FFF0'} as="u" display={'inline-block'} cursor={'pointer'}>
          Log in
        </Text>
      </Text>
    ) : (
      <Text onClick={signUPClick}>
        Don’t have an account?{' '}
        <Text color={'#00FFF0'} as="u" display={'inline-block'} cursor={'pointer'}>
          Sign up
        </Text>
      </Text>
    );
  const newTitle = userType === 'enterprise' ? (showSignUp ? 'Create Your Account' : title) : title;
  return (
    <Layout title={newTitle}>
      <Flex justifyContent={'center'} alignItems={'center'} flexDir="column" flex="1">
        {/* <div onClick={() => router.push('login?action=link')}>tst</div> */}
        {loading ? (
          <Flex minH={400} justifyItems={'center'} justifyContent={'center'} flex={1} flexDir="column">
            <Box my={4}>
              {' '}
              <Spinner thickness="4px" speed="0.65s" color="#6bb2c5" size="xl" />
            </Box>
            <Text color="#D6D6D6"> Securely logging you in...</Text>
          </Flex>
        ) : (
          <>
            <Flex flexDir="column" mt={5} textAlign="center">
              {usedLoginBottons.map((item) => (
                <Box key={item.key}>{item}</Box>
              ))}
              {showSwitchLable && (
                <Box className="">
                  <Link
                    href={`?type=web3${params && params.size > 0 ? `&${params}` : ''}`}
                    // href={`${router.pathname}?type=web3${params && params.size > 0 ? `&${params}` : ''}`}
                    className="w-full underline text-sm text-gray-400"
                  >
                    {action === 'link' ? 'link to wallet' : 'using wallet to login'}
                  </Link>
                </Box>
              )}
              {userType === 'enterprise' && <EnterpriseText />}
              <Annotations />
            </Flex>
          </>
        )}
      </Flex>
    </Layout>
  );
}
