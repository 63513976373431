import { useConnect } from 'wagmi';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { basicConfig } from '@/configs';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';

export default function useWalletConnectByEvm() {
  const useMetamask = useConnect({
    connector: new MetaMaskConnector(),
  });

  const useWalletConnectV2 = useConnect({
    connector: new WalletConnectConnector({
      options: {
        // @ts-ignore
        projectId: basicConfig.walletConnect.projectId,
      },
    }),
  });

  const useCoinbase = useConnect({
    connector: new CoinbaseWalletConnector({
      options: {
        appName: 'wagmi.sh',
        jsonRpcUrl: 'https://eth-mainnet.alchemyapi.io/v2/yourAlchemyId',
      },
    }),
  });
  function onSetProvider(provider: 'metamask' | 'walletconnect' | 'coinbase' = 'metamask') {
    if (provider === 'metamask') {
      return useMetamask;
    }
    if (provider === 'walletconnect') {
      return useWalletConnectV2;
    }
    if (provider === 'coinbase') {
      return useCoinbase;
    }
    throw new Error('Invalid provider');
  }

  return { onSetProvider };
}
