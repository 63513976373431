import React from 'react';
import CommonLoginButton from './CommonLoginButton';
import { LoginButtonProps } from '@/types/login';
import { useRouter } from 'next/router';
import { useToast } from '@chakra-ui/react';
import { useBluwhale } from '@/context/BluwhaleContext';
import useLoginByLinkedin from '@/hooks/useLoginByLinkedin';
import { useLoginResultType } from '@/configs/firebase';
import { event } from 'nextjs-google-analytics';
import { events } from '@/constants/analytics';
function LinkedinLoginButton({ name, logo, action = 'signin', userType, isSignUp = true }: LoginButtonProps) {
  const { onLink, onSignin } = useLoginByLinkedin();
  const { firebaseUser, setLoading } = useBluwhale();
  const router = useRouter();
  const toast = useToast();
  function handleRoute(result: useLoginResultType) {
    if (!result.isSuccess) {
      if (result.action === 'link') {
        toast({
          description:
            'The chosen social account is linked to another profile. Kindly opt for a different identity for your registration',
          status: 'error',
          position: 'top',
        });
      } else {
        toast.closeAll();
        toast({
          description: `${action} failure`,
          position: 'top',
          status: 'error',
        });
      }
      return;
      // throw new Error('login failure');
    }
    const params = new URLSearchParams(window.location.search);
    if (params.get('status') !== undefined) {
      params.delete('status');
    }
    const newParams = {
      ...params,
      status: 'success',
    };
    router.push(`${newParams && newParams.size > 0 ? `?${newParams.toString()}` : '?status=success'}`);
  }

  async function onClick() {
    if (userType === 'consumer') {
      event(events.linksocial_choose, {
        social: 'Linkedin',
      });
    } else {
      event(isSignUp ? events.signUpSsocial_choose : events.signInSsocial_choose, {
        social: 'Linkedin',
      });
    }
    const accessToken = firebaseUser?.token;
    const props = { userType, accessToken, isSignUp };
    const result = action === 'link' ? await onLink(props) : await onSignin(props);
    setLoading?.(false);
    handleRoute(result);
  }
  return <CommonLoginButton name={name} logo={logo} onClick={onClick} />;
}

export default LinkedinLoginButton;
