import { privy } from '@/configs';
import { PrivyAuthResponse, PrivyAuthSuccessResponse, PrivyInfo, SendMailResponse } from './types';
import axios from 'axios';

async function getPrivyInfo(accessToken: string): Promise<PrivyInfo | undefined> {
  const url = privy.apiConfig.init;
  const { headers } = privy.apiConfig;
  try {
    const response = await axios.get(url, {
      headers,
    });
    return response.data;
  } catch (e) {
    console.log('e', e);
    return undefined;
  }
}
async function sendMail(email: string): Promise<boolean> {
  const url = privy.apiConfig.sendMail;
  const { headers } = privy.apiConfig;
  try {
    const response = await axios.post(url, { email }, { headers });
    const data = response.data as SendMailResponse;
    if ('success' in data) {
      return true;
    }
    console.error('sendMail error', data);

    return false;
  } catch (e) {
    console.error('sendMail error', e);
    return false;
  }
}
function isPrivyAuthResponseSuccessed(response: PrivyAuthResponse): response is PrivyAuthSuccessResponse {
  return (response as PrivyAuthSuccessResponse).user !== undefined;
}
async function authenticate(email: string, code: string): Promise<PrivyAuthSuccessResponse | undefined> {
  const url = privy.apiConfig.auth;
  const { headers } = privy.apiConfig;
  try {
    const response = await axios.post(url, { email, code }, { headers });
    const data = response.data as PrivyAuthResponse;
    if (isPrivyAuthResponseSuccessed(data)) {
      return data;
    }
    console.error('authenticate error', data);
    return undefined;
  } catch (e) {
    console.error('authenticate error', e);
    return undefined;
  }
}

export { getPrivyInfo, sendMail, authenticate };
