import { useLoginResultType } from '@/configs/firebase';
import { useBluwhale } from '@/context/BluwhaleContext';
import useLoginByPrivy from '@/hooks/useLoginByPrivy';
import { LoginButtonProps } from '@/types/login';
import { ArrowForwardIcon, CheckIcon } from '@chakra-ui/icons';
import { Box, Button, IconButton, Image, Text, useToast } from '@chakra-ui/react';
import { User, useLogin, usePrivy } from '@privy-io/react-auth';
import { useRouter } from 'next/router';
import React from 'react';
import CommonLoginButton from './CommonLoginButton';

function PrivyLoginButton({
  name = 'privy',
  logo = '',
  action = 'signin',
  userType,
  isSignUp = true,
  referralCode,
}: LoginButtonProps) {
  const { firebaseUser, setLoading } = useBluwhale();
  const router = useRouter();
  const toast = useToast();

  const { ready, authenticated, user: PrivyUser, logout } = usePrivy();
  const { onSignin, onLink } = useLoginByPrivy();
  const disableLogin = !ready || (ready && authenticated);
  const { login } = useLogin({
    onComplete: (user: User, isNewUser: boolean, wasAlreadyAuthenticated: boolean, loginMethod: any | null) =>
      handlePrivyLoginCompleted(user, isNewUser, wasAlreadyAuthenticated, loginMethod),
    onError: handlePrivyLoginFailure,
  });
  function handleRoute(result: useLoginResultType) {
    if (!result.isSuccess) {
      if (result.action === 'link') {
        toast({
          description:
            'The chosen social account is linked to another profile. Kindly opt for a different identity for your registration',
          status: 'error',
          position: 'top',
        });
      } else {
        toast.closeAll();
        toast({
          description: `${action} failure`,
          position: 'top',
          status: 'error',
        });
      }
      return;
      // throw new Error('login failure');
    }
    const params = new URLSearchParams(window.location.search);
    if (params.get('status') !== undefined) {
      params.delete('status');
    }
    const newParams = {
      ...params,
      status: 'success',
    };
    router.push(`${newParams && newParams.size > 0 ? `?${newParams.toString()}` : '?status=success'}`);
  }
  async function handlePrivyLoginCompleted(
    user: User,
    isNewUser: boolean,
    wasAlreadyAuthenticated: boolean,
    loginMethod: any | null,
  ) {
    console.log('handlePrivyLoginCompleted', user, isNewUser, wasAlreadyAuthenticated, loginMethod);

    const accessToken = firebaseUser?.token;
    const props = { userType, accessToken, isSignUp };
    const result = action === 'link' ? await onLink(props) : await onSignin(props);
    setLoading?.(false);
    handleRoute(result);
  }
  function handlePrivyLoginFailure(error: any) {
    console.log('handlePrivyLoginFailure', error);
  }

  async function handlePrivyLogin() {
    console.log('handlePrivyLogin');
    try {
      if (PrivyUser) {
        await logout();
      }
    } catch (e) {
      console.log('handlePrivyLogin error', e);
    }
    // onSignin({ userType: 'consumer' });
    login();
  }
  return (
    <CommonLoginButton name={'privy'} logo={logo} onClick={handlePrivyLogin}>
      <Box display="flex" flexDir={'row'} alignItems={'center'} justifyContent={'space-between'} className="w-[100%]">
        <Box>
          <Text fontSize="16px" color="#D0D0D0" fontWeight="thin">
            Email Address
          </Text>
        </Box>
        <IconButton
          isRound={true}
          variant="solid"
          colorScheme="none"
          aria-label="Done"
          fontSize="20px"
          icon={<ArrowForwardIcon color="#FFFFFF" boxSize="20px" _active={{ bg: '#6235D0', borderColor: '#FFFFFF' }} />}
          bg="#6235D0"
          _active={{ bg: '#6235D0', borderColor: '#FFFFFF' }}
          _focus={{ boxShadow: 'none' }}
        />
      </Box>
    </CommonLoginButton>
  );
}

export default PrivyLoginButton;
