/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable complexity */
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import Web3LoginPlatform from './Web3LoginPlatform';
import SocialLoginPlatform from './SocialLoginPlatform';
import { useBluwhale } from '@/context/BluwhaleContext';
import { ActionType, UserType } from '@/configs/firebase';
import { event } from 'nextjs-google-analytics';
import { events } from '@/constants/analytics';
import { claimingProject, getCategories, updateProfile } from '../../../../pages/api/enterpriseapi';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
  Text,
  FormErrorMessage,
} from '@chakra-ui/react';
import { pageView } from 'nextjs-google-analytics';
import ReactGA4 from 'react-ga4';
import { claimUserRewards } from '../../../../pages/api/userClaim';
import Layout from './Layout';
import Annotations from './Annotations';
import { useGetEnterpriseDetail } from '../../../../redux/useGetEnterpriseDetail';

export type LoginPlatformProps = {
  userType?: UserType;
  action?: ActionType;
};
/**
 * @discrption
 * 1. userType: consumer | enterprise
 * 2. action: login | link
 * this component will handle the router after user login
 */
function LoginPlatform({ userType = 'consumer', action = 'signin' }: LoginPlatformProps) {
  const router = useRouter();
  const {
    type,
    projectname,
    from,
    status,
    options,
    relogin,
    claim,
    contract,
    projectName,
    claimProfile,
    rewardBlu,
    rewardUsd,
    referral,
  } = router.query;
  // console.log(referral, 'referral>>>>LoginPlatform');

  const mounted = React.useRef(false);
  const { user, onLogout, loading, setLoading } = useBluwhale();
  const { data: enterpriseDetails, status: enterpriseStatus, refetch } = useGetEnterpriseDetail();
  //@ts-ignore
  const { category } = enterpriseDetails || {};
  const toast = useToast();
  //@ts-ignore
  const [profile, setProfile] = useState<string | undefined>(claimProfile);
  //@ts-ignore
  const [blu, setBlue] = useState<string | undefined>(rewardBlu);
  //@ts-ignore
  const [usd, setusd] = useState<string | undefined>(rewardUsd);
  const [isNewUser, setIsNewUser] = useState(false);
  const [referralCode, setReferralCode] = useState(referral);
  const [categories, setCategories] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showUrlError, setShowUrlError] = useState(false);
  const [showInvitationError, setShowInvitationError] = useState(false);

  const [claimProject, setClaimProject] = useState('');
  /**
   * @param type: web3 | social
   * @param projectname: string
   * @param from: 'btn_consumer' | 'btn_enterprise'
   */

  const isWeb3 = type === 'web3';
  async function handleConsumerLoginRouter() {
    if (user?.address || user?.display_name) {
      if (profile) {
        const result = await claimUserRewards(profile);
        toast({
          description: `Successfully claimed profile`,
          status: 'success',
          position: 'top',
        });
        router.push(`wallet/${profile}`);
      } else {
        router.push(`/`);
      }
    }
  }
  function handleConsumerLinkRouter() {
    if (status === 'success') {
      handleConsumerLoginRouter();
    }
  }
  function handleConsumerRouter() {
    if (action === 'link') {
      handleConsumerLinkRouter();
      return;
    }
    if (!user?.address) {
      router.push(`/`);
      return;
    }
    router.push(`wallet/${user?.address}`);
  }
  function handleEnterpriseLinkRouter() {
    console.log('handleEnterpriseLinkRouter', status);
    if (status === 'success') {
      handleEnterpriseLoginRouter();
    }
  }
  async function handleEnterpriseLoginRouter() {
    if (enterpriseStatus === 'loading') {
      return;
    }
    if (claimProject) {
      try {
        //@ts-ignore
        const result = await claimingProject(claimProject);
        toast({
          description: `Successfully claimed `,
          status: 'success',
          position: 'top',
        });
        return;
      } catch (error) {
        toast({
          //@ts-ignore
          description: error?.response?.data?.detail || 'Something went wrong; please try again later.',
          status: 'error',
          position: 'top',
        });
      }
    } else if (user?.is_new_user || (enterpriseDetails && !category)) {
    } else if (from) {
      router.push(`${from}`);
    } else if (!projectname) {
      router.push(`/enterprise/`);
    } else {
      router.push(`/enterprise/${projectname}`);
    }
  }
  function handleEnterpriseRouter() {
    if (action === 'link') {
      handleEnterpriseLinkRouter();
      return;
    }
    handleEnterpriseLoginRouter();
  }
  async function handleRouterAfterUserLogin() {
    ReactGA4.gtag('set', 'user_properties', {
      userId: user?.uuid,
      user_type: user?.user_type,
      social_type: user?.sign_in_provider,
      user_name: user?.email || user?.display_name,
    });

    if (userType === 'consumer') {
      handleConsumerRouter();
    }
  }
  const reloginFun = async () => {
    const pathname =
      userType === 'consumer'
        ? `/login${isWeb3 ? '?type=web3' : ''}`
        : `/enterprise/login${isWeb3 ? '?type=web3' : ''}`;
    await onLogout();
    router.push(pathname);
  };
  // eslint-disable-next-line complexity
  useEffect(() => {
    if (router.isReady) {
      if (relogin || claim) {
        reloginFun();
      } else if (router.asPath.includes('login') && !user) {
        return;
      }
      if (!user) {
        const params = new URLSearchParams(window.location.search);
        if (params.get('relogin') !== undefined) {
          params.delete('relogin');
        }
        const pathname =
          userType === 'consumer'
            ? `/login${isWeb3 ? '?type=web3' : ''}`
            : `/enterprise/login${isWeb3 ? '?type=web3' : ''} ${params ? `?${params}` : ''}`;
        router.push(pathname);
        return;
      }
      //TODO
      console.log('user', user);
      handleRouterAfterUserLogin();
    }
  }, [user, status, relogin, router.isReady]);

  const web3LoginTitle = action === 'link' ? 'Link to Wallet' : 'Sign in with Wallet';
  const socialLoginTitle =
    action === 'link'
      ? 'Link to Social Media'
      : userType === 'enterprise'
      ? 'Welcome Back'
      : 'Sign in with Social Media';
  const showSwitchLable = options === 'both';

  const [selectedOption, setSelectedOption] = useState('');
  const [contractAddress, setContractAddress] = useState('');
  const [url, setUrl] = useState('');
  const [code, setCode] = useState('');
  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
    setShowError(false);
    setShowUrlError(false);
    setShowInvitationError(false);
  };

  const changeURL = (event: any) => {
    setUrl(event.target.value);
  };
  const ChangeCode = (event: any) => {
    setCode(event.target.value);
  };
  const changeContract = (event: any) => {
    setContractAddress(event.target.value);
  };

  const getCategory = async () => {
    const { categories } = await getCategories();
    setCategories(categories);
  };

  const createProfile = async () => {
    if (!selectedOption) {
      setShowError(true);
      return;
    }
    if (!url || !url.startsWith('https://')) {
      setShowUrlError(true);
      return;
    }

    if (!code || code !== 'BLUTJHKL') {
      setShowInvitationError(true);
      return;
    }
    try {
      const data = await updateProfile(selectedOption, contractAddress, url);
      if (data?.success) {
        event(events.signUp_step2_create, {
          status: 'success',
          category: selectedOption,
          contract_address: `.${contractAddress}.`,
          url,
        });
        router.replace(`/enterprise/`);
      }
    } catch (error: any) {
      toast({
        description:
          'Create failed. Please ensure that the contract address format complies with ERC20, ERC721, or ERC1155 standards.',
        status: 'error',
        position: 'top',
      });
    }
  };
  const cancel = () => {
    event(events.signUp_step2_cancel);
    onLogout();
  };
  useEffect(() => {
    if (user) {
      getCategory();
      refetch();
    }
  }, [user]);
  useEffect(() => {
    if (
      enterpriseStatus === 'success' &&
      userType === 'enterprise' &&
      (user?.is_new_user || (enterpriseDetails && !category))
    ) {
      event(events.signUp_step2_display);
      setIsNewUser(true);
    }
    if (user && userType === 'enterprise' && enterpriseStatus === 'success') {
      handleEnterpriseRouter();
    }
  }, [user?.is_new_user, category, enterpriseDetails, enterpriseStatus, userType, user]);

  useEffect(() => {
    if (contract) {
      //@ts-ignore
      setClaimProject(contract);
    }
  }, [contract]);
  if (!router.isReady) {
    return null;
  }

  if (isWeb3) {
    return (
      <Web3LoginPlatform
        action={action}
        title={web3LoginTitle}
        userType={userType}
        showSwitchLable={showSwitchLable}
        // @ts-ignore
        referralCode={referral}
        loading={loading}
      />
    );
  }
  if (isNewUser && user && userType === 'enterprise') {
    const title = 'Tell us about you';
    return (
      <Layout title={title}>
        <Flex flexDir={'row'} alignItems={'center'} w="full" py={20}>
          <Box width="50%" display={'flex'} flexDir={'row'} alignItems={'center'} gap={6} justifyContent={'center'}>
            <Avatar src={user?.photo_url} w={75} h={75} />
            <Box>
              <Text textAlign={'left'} fontSize={26}>
                {user?.display_name}
              </Text>
              <Text textAlign={'left'}>
                --{' '}
                <Text display="inline-block" opacity={0.6}>
                  wallets
                </Text>
              </Text>
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexDir={'column'}
            gap={6}
            borderLeft={'1px solid #FFFFFF80'}
            borderLeftWidth={1}
            px={10}
            pl={16}
            h={'full'}
            width="50%"
          >
            <FormControl isRequired isInvalid={showError}>
              <FormLabel>Category</FormLabel>
              <Select
                color={selectedOption ? 'white' : 'gray'}
                value={selectedOption}
                onChange={handleChange}
                placeholder="-Select One-"
              >
                {categories?.length > 0 &&
                  categories.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
              </Select>
              <FormErrorMessage fontSize={12}>*You need to choose at least one category</FormErrorMessage>
            </FormControl>
            {/* <FormControl>
              <FormLabel>Project contract address</FormLabel>
              <Input
                type={'text'}
                placeholder="0x81A..."
                onChange={changeContract}
                value={contractAddress}
                border={'1px solid #767676'}
              />
            </FormControl> */}
            <FormControl isRequired isInvalid={showUrlError}>
              <FormLabel>Project URL</FormLabel>
              <Input
                type={'text'}
                placeholder="https://"
                onChange={changeURL}
                value={url}
                border={'1px solid #767676'}
              />
              <FormErrorMessage fontSize={12}>*You need to provide valid project url</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={showInvitationError}>
              <FormLabel>Invitation Code</FormLabel>
              <Input type={'text'} onChange={ChangeCode} value={code} border={'1px solid #767676'} />
              <FormErrorMessage fontSize={12}>*Invalid invitation code</FormErrorMessage>
            </FormControl>
            <ButtonGroup variant="outline" mt={[4, null, 0]}>
              <Button
                colorScheme="white"
                px={[3, null, 14]}
                py={[2, null, 6]}
                rounded="full"
                color="black"
                fontWeight="bold"
                fontSize={['sm', null, 'md']}
                bg="white"
                onClick={cancel}
              >
                Cancel
              </Button>
              <Button
                colorScheme="gray"
                bg="#6235D0"
                px={[6, null, 16]}
                py={[2, null, 6]}
                rounded="full"
                color="white"
                fontWeight="bold"
                fontSize={['sm', null, 'md']}
                onClick={createProfile}
              >
                Create
              </Button>
            </ButtonGroup>
          </Box>
        </Flex>

        <Annotations />
      </Layout>
    );
  }
  return (
    <SocialLoginPlatform
      action={action}
      title={socialLoginTitle}
      userType={userType}
      showSwitchLable={showSwitchLable}
      claimProject={claimProject !== ''}
      loading={loading}
    />
  );
}

export default LoginPlatform;
