import { basicConfig } from '@/configs';
import { Box } from '@chakra-ui/react';
import React from 'react';
import Link from 'next/link';

function Annotations() {
  return (
    <Box className="flex" mx={['auto', null, '10%']} p="1">
      <Box className="text-sm text-[#7C7C7C]  flex-1 self-center text-center">
        By connecting your wallet, <br />
        you agree to our
        <Link href={basicConfig.termsOfUse} passHref legacyBehavior className="w-full">
          <a target="_blank" rel="noopener noreferrer" className="mx-1  underline">
            Terms of Services
          </a>
        </Link>
        and our
        <Link href={basicConfig.termsOfUse} passHref legacyBehavior className="w-full ">
          <a target="_blank" rel="noopener noreferrer" className="mx-1  underline">
            Privacy Policy.
          </a>
        </Link>
      </Box>
    </Box>
  );
}

export default Annotations;
