import { Flex, Box, Text } from '@chakra-ui/react';
import React from 'react';

function Layout({ children, title }: { children: React.ReactNode; title: string }) {
  return (
    <Flex justifyContent={'center'} flexDir={'column'} alignItems={'center'} mx={'auto'}>
      <Box
        position="relative"
        w={['100%', null, '3xl']}
        p="6"
        px={[4, 12, 12]}
        rounded="2xl"
        bg="rgba(35, 35, 35, 0.6)"
        mt={[0, null, '7']}
        textAlign="center"
        display={'flex'}
        flexDir={'column'}
      >
        <Text color="#fff" mx="4" fontSize={['xl', null, '3xl']} fontWeight="bold">
          {title}
        </Text>
        <Box className=" flex-col items-center justify-center text-center" flexDirection={['column', null, 'row']}>
          {children}
        </Box>
      </Box>
    </Flex>
  );
}

export default Layout;
