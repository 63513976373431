import React from 'react';
import CommonLoginButton from './CommonLoginButton';
import { LoginButtonProps } from '@/types/login';

function FacebookLoginButton({ name, logo, action = 'signin' }: LoginButtonProps) {
  async function onClick() {
    //TODO
    console.log('FacebookLoginButton');
    // login();
  }
  return <CommonLoginButton name={name} logo={logo} onClick={onClick} />;
}

export default FacebookLoginButton;
