import { UserType, auth, useLoginResultType } from '@/configs/firebase';
import { BluwhaleUserType, useBluwhale } from '@/context/BluwhaleContext';
import useWalletConnectByEvm from './useWalletConnectByEvm';
import { useAccount, useDisconnect, useSignMessage } from 'wagmi';
import _ from 'cypress/types/lodash';
import { useRef } from 'react';
import { useRouter } from 'next/router';
import { basicConfig } from '@/configs';
import useLoginByBluwhaleAuth from './useLoginByBluwhaleAuth';
import { onLoginWeb3OptionType } from '@/types/login';

export type useLoginByWeb3OptionType = {
  web3Provider: 'metamask' | 'walletconnect' | 'coinbase';
};
export type onLoginOptionType = {
  accessToken?: string;
  provider?: 'metamask' | 'walletconnect' | 'coinbase' | undefined;
  userType: UserType;
  referralCode?: string;
};
export default function useLoginByWeb3({ web3Provider = 'metamask' }: useLoginByWeb3OptionType) {
  const BASIC_URL = basicConfig.auth.servicesBasicUrl;
  const router = useRouter();
  const { address, isConnected } = useAccount();
  const { disconnectAsync } = useDisconnect();
  const { signMessageAsync } = useSignMessage();
  const { onSetProvider } = useWalletConnectByEvm();
  const { onSetUser, user, setLoading } = useBluwhale();
  const { onFirebaseWeb3ReqeustMessage, onFirebaseWeb3Signin, onLink, onSignin } = useLoginByBluwhaleAuth({
    BASIC_URL,
  });

  const connectWalletProviderRef = useRef<any>(onSetProvider(web3Provider));

  async function init(_provider: 'metamask' | 'walletconnect' | 'coinbase' | undefined = undefined) {
    try {
      if (isConnected) {
        await disconnectAsync();
      }
      if (_provider) {
        connectWalletProviderRef.current = onSetProvider(_provider);
      }
      const result = await connectWalletProviderRef.current.connectAsync();
      const { account, chain } = result;
      console.log('account', account);
      return { account, chain };
    } catch (e) {
      console.error('init error', e);
      throw new Error(`connectAsync error: ${e}`);
    }
  }

  async function signMessage(message: string) {
    try {
      const signature = await signMessageAsync({ message });
      return signature;
    } catch (e) {
      console.error(`signMessage error :${e}`);
      return undefined;
    }
  }

  async function web3Sign({
    provider,
    type = 'signin',
  }: {
    provider?: 'metamask' | 'walletconnect' | 'coinbase' | undefined;
    type: 'link' | 'signin';
  }): Promise<{ credential: any; token: string }> {
    const { account, chain } = await init(provider);
    const message = await onFirebaseWeb3ReqeustMessage({ address: account, chainId: chain.id, type });
    const signature = await signMessage(message);

    setLoading?.(true);
    if (!signature) {
      setLoading?.(false);

      throw new Error('No signature received');
    }
    const firebaseResult = await onFirebaseWeb3Signin({ message, signature });
    const firebaseToken = firebaseResult?.token;
    if (!firebaseToken) {
      setLoading?.(false);

      throw new Error('No firebaseToken received');
    }
    return firebaseResult;
  }

  /**
   *
   * @param param0
   * @returns
   * @description
   * The login flow is as follows:
   * 1. Connect to the wallet
   * 2. Request a message to sign
   * 3. Sign the message
   * 4. Send the message and signature to the server
   * 5. Receive the token from the server
   * 6. Sign in with the token or link with the token to create Bluwhale account
   */
  async function signin({
    provider,
    userType,
    referralCode,
  }: onLoginOptionType): Promise<useLoginResultType | any | undefined> {
    try {
      const firebaseResult = await web3Sign({ provider, type: 'signin' });
      const { credential, token: firebaseToken } = firebaseResult;

      const bluwhaleUser = await onSignin({
        token: firebaseToken,
        userType,
        isSignUp: true,
        referralCode,
      });
      if (!bluwhaleUser) {
        throw new Error('No result received');
      }
      const result = { user: bluwhaleUser, credential };
      onSetUser(result);
      return { result, action: 'signin', isSuccess: true };
    } catch (e) {
      setLoading?.(false);

      console.log('e', e);
      return { result: undefined, action: 'signin', isSuccess: false, error: e };
    }
  }
  async function link({
    accessToken,
    provider,
    userType,
  }: onLoginWeb3OptionType): Promise<useLoginResultType | any | undefined> {
    try {
      if (!accessToken) {
        throw new Error('No accessToken received');
      }

      const firebaseResult = await web3Sign({ provider, type: 'link' });
      const { credential, token: firebaseToken } = firebaseResult;
      const preToken = accessToken ?? (await auth.currentUser?.getIdToken());

      const bluwhaleUser = await onLink({ token: firebaseToken, preToken, userType, isSignUp: true });
      if (!bluwhaleUser) {
        setLoading?.(false);

        throw new Error('No bluwhaleUser received');
      }
      const result = { user: bluwhaleUser, credential };
      setLoading?.(false);

      onSetUser(result);
      return { result, action: 'link', isSuccess: true };
    } catch (e) {
      setLoading?.(false);

      console.log('e', e);
      return { result: undefined, action: 'link', isSuccess: false, error: e };
    }
  }
  return { onSignin: signin, onLink: link };
}
